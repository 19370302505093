<template>
  <v-dialog v-model="dialogFormulas" persistent max-width="900px">
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ str['update_macros'] }}
        </span>
      </v-card-title>
      <v-card-text style="padding-top: 10px; padding-bottom: 10px">
        <v-container v-if="formulasData" style="padding-top: 0; padding-bottom: 0">
          <v-row>
            <v-col cols="12">
              <div style="display: flex">
                <v-text-field
                  v-for="(inputItem, index) in formulasData.inputs"
                  :key="index"
                  v-model="formulasData.values[inputItem.id]"
                  :label="inputItem.title"
                  required
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                  :class="{ 'mr-6': index < formulasData.inputs.length - 1 }"
                  style="max-width: 120px"
                />
              </div>
            </v-col>
            <v-row>
              <v-col cols="6">
                <div
                  v-if="
                    (!formulasData.values.weight && formulasData.values.weight !== 0) ||
                    (!formulasData.values.height && formulasData.values.height !== 0) ||
                    (!formulasData.values.age && formulasData.values.age !== 0) ||
                    (!formulasData.values.physical_activity_level && formulasData.values.physical_activity_level !== 0)
                  "
                  class="text-center"
                  style="padding: 40px 0 100px"
                >
                  {{ str['formulas_empty'] }}
                </div>
                <div
                  v-if="
                    (formulasData.values.weight || formulasData.values.weight === 0) &&
                    (formulasData.values.height || formulasData.values.height === 0) &&
                    (formulasData.values.age || formulasData.values.age === 0) &&
                    (formulasData.values.physical_activity_level || formulasData.values.physical_activity_level === 0)
                  "
                >
                  <div>
                    <div class="mt-4 mb-2" style="font-weight: bold">
                      {{ str['formula_mifflin'] }}
                    </div>
                    <div class="mb-1">{{ str['female'] }}: {{ getFormula('mifflin_female') }} kcal</div>
                    <div>{{ str['male'] }}: {{ getFormula('mifflin_male') }} kcal</div>
                  </div>
                  <div>
                    <div class="mt-4 mb-2" style="font-weight: bold">
                      {{ str['formula_harris'] }}
                    </div>
                    <div class="mb-1">{{ str['female'] }}: {{ getFormula('harris_female') }} kcal</div>
                    <div>{{ str['male'] }}: {{ getFormula('harris_male') }} kcal</div>
                  </div>
                  <div style="border-top: 1px solid #ddd; margin-top: 15px">
                    <div style="display: flex; padding: 10px 0 15px">
                      <v-text-field
                        v-for="(inputItem, index) in formulasData.table.inputs"
                        :key="index"
                        v-model="formulasData.values[inputItem.id]"
                        :label="inputItem.title"
                        required
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                        :class="{
                          'mr-6': index < formulasData.table.inputs.length - 1,
                        }"
                        style="max-width: 120px"
                        hide-details
                      />
                    </div>
                    <div class="formulas-table">
                      <table>
                        <thead>
                          <tr>
                            <td v-for="(header, headerIndex) in formulasData.table.data.headers" :key="headerIndex">
                              {{ str[header] }}
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(row, rowIndex) in formulasData.table.data.body" :key="rowIndex">
                            <td v-for="(col, colIndex) in row" :key="colIndex">
                              <div v-if="col.input">
                                <v-text-field
                                  v-model="formulasData.values[col.id]"
                                  required
                                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                  style="max-width: 80px; padding: 0; margin: 0"
                                  hide-details
                                />
                              </div>
                              <div v-if="!col.input && col.calc">
                                {{ col.calc() }}
                              </div>
                              <div v-if="!col.input && !col.calc">
                                {{ str[col.id] }}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="6">
                <div class="text-center">
                  <img :src="require('@/assets/formulas-table-1.jpg')" style="width: 100%; margin-bottom: 20px" />
                </div>
                <div class="text-center">
                  <img :src="require('@/assets/formulas-table-2.jpg')" style="width: 100%" />
                </div>
              </v-col>
            </v-row>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions style="padding-top: 40px">
        <v-spacer></v-spacer>
        <v-btn color="default" @click="close">
          {{ str['cancel'] }}
        </v-btn>
        <v-btn color="success" @click="saveFormulas">
          {{ str['save'] }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'

export default {
  name: 'ClientFormulas',
  props: {
    client: Object,
    foodPlan: Object,
    day: Object,
    refreshCallback: Function,
    mountedCallback: Function,
  },
  data() {
    return {
      user: Utils.getUser(),
      str: window.strings,
      dialogFormulas: false,
      formulasData: null,
    }
  },
  mounted: function () {
    if (this.mountedCallback) {
      this.mountedCallback()
    }
  },
  methods: {
    open: function () {
      const self = this
      const ageDifMs = Date.now() - new Date(this.client.birth_date).getTime()
      const ageDate = new Date(ageDifMs)
      const age = Math.abs(ageDate.getUTCFullYear() - 1970)
      const userValues = this.getFormulasUserValues()
      this.$isLoading(true)
      Api.getLastPhysicalEvaluation(
        {
          id: this.client.dbId,
          useCache: true,
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            let weight = userValues.weight ? userValues.weight : response.data[0]?.body ? response.data[0].body.weight : 0
            let height = userValues.height ? userValues.height : response.data[0]?.body ? response.data[0].body.height : 0

            if (self.user?.configurations?.food_plans?.use_formulas_last_weight) {
              if (response.data[0]?.body.weight) {
                weight = response.data[0].body.weight
              }
              if (response.data[0]?.body.height) {
                height = response.data[0].body.height
              }
            }

            self.formulasData = {
              inputs: [
                {
                  id: 'weight',
                  title: window.strings['weight'],
                },
                {
                  id: 'height',
                  title: window.strings['height_cm'],
                },
                {
                  id: 'age',
                  title: window.strings['age'],
                },
                {
                  id: 'physical_activity_level',
                  title: window.strings['physical_activity_level'],
                },
              ],
              values: {
                weight: weight,
                height: height,
                age: age,
                physical_activity_level: userValues.physical_activity_level ? userValues.physical_activity_level : 1,
                calories_goal: userValues.calories_goal ? userValues.calories_goal : 0,
                protein: userValues.protein ? userValues.protein : 0,
                fat: userValues.fat ? userValues.fat : 0,
                carbs: 0,
                protein_goal: 0,
                fat_goal: 0,
                carbs_goal: 0,
                total_kcal_protein: 0,
                total_kcal_fat: 0,
                total_kcal_carbs: 0,
              },
              keysToSave: ['calories_goal', 'protein_goal', 'fat_goal', 'carbs_goal'],
              table: {
                inputs: [
                  {
                    id: 'calories_goal',
                    title: window.strings['daily_kcal'],
                  },
                ],
                data: {
                  headers: ['', 'protein', 'fat', 'hydrates'],
                  body: [
                    [
                      {
                        input: false,
                        id: 'goal_g_kg',
                      },
                      {
                        input: true,
                        id: 'protein',
                      },
                      {
                        input: true,
                        id: 'fat',
                      },
                      {
                        input: false,
                        id: 'carbs',
                        calc: function () {
                          const weight = parseFloat(self.formulasData.values.weight)
                          const kcal = parseFloat(self.formulasData.values.calories_goal)
                          const protein = parseFloat(self.formulasData.values.protein)
                          const fat = parseFloat(self.formulasData.values.fat)
                          const value = ((kcal - protein * weight * 4 - fat * weight * 9) / (weight * 4)).toFixed(2)
                          self.formulasData.values.carbs = parseFloat(value)
                          return value
                        },
                      },
                    ],
                    [
                      {
                        input: false,
                        id: 'total_g',
                      },
                      {
                        input: false,
                        calc: function () {
                          const weight = parseFloat(self.formulasData.values.weight)
                          const protein = parseFloat(self.formulasData.values.protein)
                          const value = (protein * weight).toFixed(0)
                          self.formulasData.values.protein_goal = parseFloat(value)
                          return value
                        },
                      },
                      {
                        input: false,
                        calc: function () {
                          const weight = parseFloat(self.formulasData.values.weight)
                          const fat = parseFloat(self.formulasData.values.fat)
                          const value = (fat * weight).toFixed(0)
                          self.formulasData.values.fat_goal = parseFloat(value)
                          return value
                        },
                      },
                      {
                        input: false,
                        calc: function () {
                          const weight = parseFloat(self.formulasData.values.weight)
                          const carbs = parseFloat(self.formulasData.values.carbs)
                          const value = (carbs * weight).toFixed(0)
                          self.formulasData.values.carbs_goal = parseFloat(value)
                          return value
                        },
                      },
                    ],
                    [
                      {
                        input: false,
                        id: 'total_kcal',
                      },
                      {
                        input: false,
                        calc: function () {
                          const kcal = parseFloat(self.formulasData.values.calories_goal)
                          const value = (((self.formulasData.values.protein_goal * 4) / kcal) * 100).toFixed(0)
                          self.formulasData.values.total_kcal_protein = value
                          return value
                        },
                      },
                      {
                        input: false,
                        calc: function () {
                          const kcal = parseFloat(self.formulasData.values.calories_goal)
                          const value = (((self.formulasData.values.fat_goal * 9) / kcal) * 100).toFixed(0)
                          self.formulasData.values.total_kcal_fat = value
                          return value
                        },
                      },
                      {
                        input: false,
                        calc: function () {
                          const kcal = parseFloat(self.formulasData.values.calories_goal)
                          const value = (((self.formulasData.values.carbs_goal * 4) / kcal) * 100).toFixed(0)
                          self.formulasData.values.total_kcal_carbs = value
                          return value
                        },
                      },
                    ],
                  ],
                },
              },
            }
            self.dialogFormulas = true
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    getFormula: function (key) {
      let value = ''
      switch (key) {
        case 'mifflin_female':
          value = 10 * this.formulasData.values.weight + 6.25 * this.formulasData.values.height - 5 * this.formulasData.values.age - 161
          break
        case 'mifflin_male':
          value = 10 * this.formulasData.values.weight + 6.25 * this.formulasData.values.height - 5 * this.formulasData.values.age + 5
          break
        case 'harris_female':
          value =
            655.1 + 9.563 * this.formulasData.values.weight + 1.85 * this.formulasData.values.height - 4.676 * this.formulasData.values.age
          break
        case 'harris_male':
          value =
            66.5 + 13.75 * this.formulasData.values.weight + 5.003 * this.formulasData.values.height - 6.75 * this.formulasData.values.age
          break
      }
      return (value * this.formulasData.values.physical_activity_level).toFixed(0)
    },
    getFormulasUserValues: function () {
      try {
        let microcycle = this.client.microcycle
        if (microcycle) {
          microcycle = JSON.parse(microcycle)
          if (microcycle.food_plan_fields) {
            return microcycle.food_plan_fields
          }
        }
      } catch (error) {
        return {}
      }
      return {}
    },
    saveFormulas: function () {
      const self = this
      const macrosGoals = {}

      this.$isLoading(true)

      this.formulasData.keysToSave.forEach(function (key) {
        if (self.day || self.foodPlan) {
          if (self.day) {
            self.refreshCallback('day', key, self.formulasData.values[key])
          } else {
            self.refreshCallback('foodPlan', key, self.formulasData.values[key])
          }
        } else {
          macrosGoals[key] = self.formulasData.values[key]
        }
      })

      const userDataToSave = {}
      this.formulasData.inputs.forEach(function (item) {
        userDataToSave[item.id] = self.formulasData.values[item.id]
      })
      this.formulasData.table.inputs.forEach(function (item) {
        userDataToSave[item.id] = self.formulasData.values[item.id]
      })
      this.formulasData.table.data.body.forEach(function (row) {
        row.forEach(function (item) {
          if (item.input) {
            userDataToSave[item.id] = self.formulasData.values[item.id]
          }
        })
      })

      let microcycle = this.client.microcycle
      if (microcycle) {
        microcycle = JSON.parse(microcycle)
      } else {
        microcycle = {}
      }
      microcycle.food_plan_fields = userDataToSave

      if (!this.day && !this.foodPlan) {
        microcycle.food_plan_macros_goals = macrosGoals
      }

      Api.updateUser(
        {
          id: this.client.id,
          microcycle: JSON.stringify(microcycle),
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.client.setValue('microcycle', response.data.microcycle)
            Utils.setStorage('client', self.client)
            self.close()
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    close: function () {
      this.dialogFormulas = false
      this.formulasData = null
    },
  },
}
</script>
